'use client'

import { NavBar, Route } from '@ethena/shared-ui/src/components/NavBar/NavBar'
import { SocialsButton } from '@ethena/shared-ui/src/components/SocialsButton'
import { Stats } from '@ethena/shared-ui/src/components/Stats'
import { StatWithIcon } from '@ethena/shared-ui/src/components/StatWithIcon'
import { useScrollbarWidth } from '@ethena/shared-ui/src/hooks/useScrollbarWidth'
import { usePathname } from 'next/navigation'

import clsx from 'clsx'
import { useState } from 'react'

import { IS_GERMAN } from '@/constants'
import { useEeaTvl } from '@/hooks'
import useUserStore from '@/store/user-store'

const NavBarRight = () => {
  const [isSocialsOpen, setIsSocialsOpen] = useState(false)

  const { isIdle } = useUserStore()
  const { eeaTvl } = useEeaTvl()
  return (
    <div className="sm:flex hidden">
      <div
        className={clsx('transition-all ease-in duration-150', {
          'opacity-0 translate-x-1/2': isSocialsOpen,
          'opacity-100': !isSocialsOpen,
        })}
      >
        {!IS_GERMAN ? (
          <Stats isIdle={isIdle} />
        ) : (
          <StatWithIcon
            label="Balance"
            value={eeaTvl}
            tooltip={`Amount of USDe in circulation, issued by Ethena GmbH`}
            imgSrc="/shared/usde.svg"
            imgClasses="flex"
          />
        )}
      </div>

      <SocialsButton isOpen={isSocialsOpen} setIsOpen={setIsSocialsOpen} />
    </div>
  )
}

const NavBarBottomMobile = () => {
  const { isIdle } = useUserStore()
  return IS_GERMAN ? null : <Stats isIdle={isIdle} isMobileMenuStats />
}

export function LandingWrapper({
  children,
  hiddenRoutes,
  routes,
}: {
  children: React.ReactNode
  routes: Route[]
  hiddenRoutes: string[]
}) {
  const scrollbarWidth = useScrollbarWidth()
  const pathname = usePathname()

  const checkRouteMatch = () => {
    if (!pathname) return false
    if (hiddenRoutes.includes(pathname)) return true

    return routes.some(
      routeObj => routeObj.route === pathname || routeObj.routes?.some(nestedRoute => nestedRoute.route === pathname),
    )
  }

  return (
    <div>
      <div
        className={clsx(
          'flex min-h-[calc(100vh-20px)] sm:min-h-[calc(100vh-40px)] flex-col rounded-4xl mx-2.5 sm:mx-[30px] my-2.5 sm:my-5 z-20 top-0 left-0 right-0 bottom-0 relative',
          `w-[calc(100vw - ${scrollbarWidth}px] max-w-[2000px] 4xl:mx-auto`,
          checkRouteMatch() ? 'layout-background-image border-2 border-cblue-600' : '',
        )}
      >
        <NavBar routes={routes} right={<NavBarRight />} bottomMobile={<NavBarBottomMobile />} />
        {children}
      </div>
    </div>
  )
}
