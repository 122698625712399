import { formatNumber, NumberType } from '@ethena/shared-ui'

import { format, parseISO } from 'date-fns'
import { useMemo } from 'react'
import useSWR from 'swr'

import { EeaTvlBreakdownResponse } from '@/app/api/eea/tvl-breakdown/route'
import { IS_GERMAN } from '@/constants'

export const useEeaTvl = () => {
  const { data } = useSWR<EeaTvlBreakdownResponse>(IS_GERMAN ? `/api/eea/tvl-breakdown` : null)

  return useMemo(() => {
    if (!IS_GERMAN) return { eeaTvl: '', lastUpdated: '' }

    if (!data) return { eeaTvl: undefined, lastUpdated: undefined }

    return {
      eeaTvl: formatNumber(data.totalEeaTvl ?? 0, NumberType.TokenBalance),
      lastUpdated: data.lastUpdated ? format(parseISO(data.lastUpdated), 'd MMM yy') : '',
    }
  }, [data])
}
