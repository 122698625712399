'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

import clsx from 'clsx'

interface NavItemProps {
  readonly title: string
  readonly route: string
  readonly classes?: string
  readonly padding?: string
  readonly isActiveOverride?: boolean
  readonly isDropdownItem?: boolean
}

export function NavItem({
  classes,
  isActiveOverride,
  isDropdownItem,
  padding = 'py-6 px-3 lg:px-4',
  route,
  title,
}: NavItemProps) {
  const path = usePathname()
  const isActive = path === route || isActiveOverride
  return (
    <Link
      href={route}
      className={clsx(
        'text-sm whitespace-nowrap',
        isActive ? 'text-cblue-400' : 'hover:text-cblue-200',
        !isDropdownItem && isActive ? 'selected-nav-item' : '',
        classes,
        padding,
      )}
      target={route.startsWith('/') ? undefined : '_blank'}
    >
      {title}
    </Link>
  )
}
