'use client'

import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { RxHamburgerMenu } from 'react-icons/rx'

import { cn } from '../../../utils'
import { Socials } from '../Socials'

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from './DropdownMenu'
import { NavItem } from './NavItem'
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger } from './Sheet'
export interface Route {
  title: string
  route: string
  isActiveOverride?: boolean
  cutToMenuBreakpoint: 'md:block' | 'lg:block' | 'xl:block'
  routes?: Route[]
}

interface NavBarProps {
  routes: Route[]
  right?: React.ReactNode
  bottomMobile?: React.ReactNode
  className?: string
}

export const NavBar: FC<NavBarProps> = ({ bottomMobile, className, right, routes }) => {
  const path = usePathname()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280 && open) {
        setOpen(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [open])

  return (
    <nav
      className={cn(
        'z-20 box-content backdrop-blur-[1.5px] h-12 sm:h-16 px-3 sm:px-5 flex items-center border border-neutrals-700 bg-black bg-opacity-30 rounded-full mt-8 mx-6',
        className,
      )}
    >
      <Link href="/" className="flex h-full items-center">
        <Image
          src="/shared/ethena.svg"
          alt="Ethena Logo"
          priority
          width={0}
          height={0}
          style={{ height: '50%', width: 'auto' }}
          className="mr-2"
        />
        <span className={clsx('mr-2 text-xl sm:text-2xl sm:mr-8 lg:mr-9 xl:mr-6 2xl:mr-9')}>Ethena</span>
      </Link>
      <div className="flex items-center justify-between w-full">
        {/* Desktop Menu */}
        <ul className="flex items-center">
          {routes.map(({ cutToMenuBreakpoint, isActiveOverride, route, routes: subRoutes, title }) =>
            subRoutes ? (
              <li
                key={title}
                className={clsx('flex w-full', {
                  [`hidden ${cutToMenuBreakpoint}`]: !!cutToMenuBreakpoint,
                })}
              >
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex items-center gap-2 text-sm hover:text-cblue-100 py-6 px-3 lg:px-4">
                    {title} <BiChevronDown />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="black-gradient rounded-md p-0">
                    {subRoutes.map(({ route, title }) => (
                      <DropdownMenuItem key={title} isDropdownItem isActive={path === route}>
                        <NavItem
                          key={title}
                          isActiveOverride={path === route}
                          route={route}
                          title={title}
                          classes="w-full"
                          padding=""
                          isDropdownItem={true}
                        />
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </li>
            ) : (
              <li key={title} className={cn(cutToMenuBreakpoint ? `hidden ${cutToMenuBreakpoint}` : undefined)}>
                <NavItem isActiveOverride={isActiveOverride} route={route} title={title} />
              </li>
            ),
          )}
        </ul>
        <div className="flex items-center">{right}</div>
      </div>
      {/* Mobile Menu */}
      {!!routes.length && (
        <div className="xl:hidden">
          <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild onClick={() => setOpen(true)}>
              <div className="inline-flex w-full items-center justify-center gap-1 rounded-sm bg-black bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative p-2 text-sm text-gray-200">
                {open ? (
                  <span className="w-5 z-10">
                    <Image src="/shared/close-icon.svg" alt="Close" width={16} height={16} />
                  </span>
                ) : (
                  <RxHamburgerMenu className="w-5 text-xl" />
                )}
              </div>
            </SheetTrigger>
            <SheetContent onCloseAutoFocus={() => setOpen(false)} side="middle">
              <div className="flex h-full flex-col justify-between">
                <div className="flex flex-col gap-2">
                  <SheetHeader>
                    <SheetTitle className="sr-only">Links</SheetTitle>
                    <SheetDescription className="sr-only">Menu Links</SheetDescription>
                  </SheetHeader>
                  <ul className="flex flex-col gap-4">
                    {[
                      ...routes.flatMap(({ isActiveOverride, route, routes: subRoutes, title }) =>
                        subRoutes
                          ? subRoutes.map(subRoute => ({
                              isActiveOverride: path === subRoute.route,
                              route: subRoute.route,
                              title: subRoute.title,
                            }))
                          : [{ isActiveOverride, route, title }],
                      ),
                    ].map(({ isActiveOverride, route, title }) => (
                      <li key={title}>
                        <Link
                          onClick={() => setOpen(false)}
                          href={route}
                          className={`relative group flex items-center whitespace-nowrap py-2 px-4 text-sm w-3/4 ${
                            isActiveOverride || path === route ? 'text-cblue-200 selected-nav-item-mobile' : ''
                          }`}
                        >
                          {title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <SheetFooter className="p-4 mt-4 flex flex-col gap-4">
                  <Socials className="justify-between gap-3 flex-wrap w-fit" />
                  {bottomMobile}
                </SheetFooter>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      )}
    </nav>
  )
}
