'use client'

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { ChevronRight } from 'lucide-react'
import * as React from 'react'

import { cn } from '../../../utils'
import { baiJamjuree } from '../../constants/theme'

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md',
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 border border-[#273248] bg-black shadow-lg ring-1 ring-black ring-opacity-5',
        className,
        baiJamjuree.className,
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
))

DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean
    isActive?: boolean
    isDropdownItem?: boolean
  }
>(({ children, className, inset, isActive, isDropdownItem }, ref) => {
  return (
    <DropdownMenuPrimitive.Item
      ref={ref}
      className={cn(
        'relative flex group cursor-default select-none items-center gap-2 rounded-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&>svg]:size-4 [&>svg]:shrink-0 transition-all group w-full whitespace-nowrap py-3 px-4 text-sm text-gray-200',
        inset && 'pl-8',
        className,
        isActive ? 'text-cblue-200 selected-nav-item-mobile' : 'text-gray-200',
        isDropdownItem && !isActive ? 'transition-all hover:translate-x-2' : '',
      )}
    >
      {!isActive && isDropdownItem && (
        <ChevronRight className="absolute -left-1 hidden group-hover:flex h-5 w-5 text-gray-200" />
      )}
      {children}
    </DropdownMenuPrimitive.Item>
  )
})

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

export { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem }
