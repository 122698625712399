/* eslint-disable max-lines */
export const DISCORD_LINK = 'https://discord.gg/ethena'
export const TWITTER_IP_RANGES = ['199.16.156.0/22', '199.59.148.0/22', '192.133.76.0/22']

// https://developers.google.com/static/search/apis/ipranges/googlebot.json
export const GOOGLE_IP_RANGES = [
  '192.178.5.0/27',
  '192.178.6.0/27',
  '192.178.6.32/27',
  '34.100.182.96/28',
  '34.101.50.144/28',
  '34.118.254.0/28',
  '34.118.66.0/28',
  '34.126.178.96/28',
  '34.146.150.144/28',
  '34.147.110.144/28',
  '34.151.74.144/28',
  '34.152.50.64/28',
  '34.154.114.144/28',
  '34.155.98.32/28',
  '34.165.18.176/28',
  '34.175.160.64/28',
  '34.176.130.16/28',
  '34.22.85.0/27',
  '34.64.82.64/28',
  '34.65.242.112/28',
  '34.80.50.80/28',
  '34.88.194.0/28',
  '34.89.10.80/28',
  '34.89.198.80/28',
  '34.96.162.48/28',
  '35.247.243.240/28',
  '66.249.64.0/27',
  '66.249.64.128/27',
  '66.249.64.160/27',
  '66.249.64.224/27',
  '66.249.64.32/27',
  '66.249.64.64/27',
  '66.249.64.96/27',
  '66.249.65.0/27',
  '66.249.65.128/27',
  '66.249.65.160/27',
  '66.249.65.192/27',
  '66.249.65.224/27',
  '66.249.65.32/27',
  '66.249.65.64/27',
  '66.249.65.96/27',
  '66.249.66.0/27',
  '66.249.66.160/27',
  '66.249.66.192/27',
  '66.249.66.32/27',
  '66.249.66.64/27',
  '66.249.66.96/27',
  '66.249.68.0/27',
  '66.249.68.32/27',
  '66.249.68.64/27',
  '66.249.68.96/27',
  '66.249.69.0/27',
  '66.249.69.128/27',
  '66.249.69.160/27',
  '66.249.69.192/27',
  '66.249.69.224/27',
  '66.249.69.32/27',
  '66.249.69.64/27',
  '66.249.69.96/27',
  '66.249.70.0/27',
  '66.249.70.128/27',
  '66.249.70.160/27',
  '66.249.70.192/27',
  '66.249.70.224/27',
  '66.249.70.32/27',
  '66.249.70.64/27',
  '66.249.70.96/27',
  '66.249.71.0/27',
  '66.249.71.128/27',
  '66.249.71.160/27',
  '66.249.71.192/27',
  '66.249.71.224/27',
  '66.249.71.32/27',
  '66.249.71.64/27',
  '66.249.71.96/27',
  '66.249.72.0/27',
  '66.249.72.128/27',
  '66.249.72.160/27',
  '66.249.72.192/27',
  '66.249.72.224/27',
  '66.249.72.32/27',
  '66.249.72.64/27',
  '66.249.72.96/27',
  '66.249.73.0/27',
  '66.249.73.128/27',
  '66.249.73.160/27',
  '66.249.73.192/27',
  '66.249.73.224/27',
  '66.249.73.32/27',
  '66.249.73.64/27',
  '66.249.73.96/27',
  '66.249.74.0/27',
  '66.249.74.128/27',
  '66.249.74.160/27',
  '66.249.74.32/27',
  '66.249.74.64/27',
  '66.249.74.96/27',
  '66.249.75.0/27',
  '66.249.75.128/27',
  '66.249.75.160/27',
  '66.249.75.192/27',
  '66.249.75.224/27',
  '66.249.75.32/27',
  '66.249.75.64/27',
  '66.249.75.96/27',
  '66.249.76.0/27',
  '66.249.76.128/27',
  '66.249.76.160/27',
  '66.249.76.192/27',
  '66.249.76.224/27',
  '66.249.76.32/27',
  '66.249.76.64/27',
  '66.249.76.96/27',
  '66.249.77.0/27',
  '66.249.77.128/27',
  '66.249.77.160/27',
  '66.249.77.192/27',
  '66.249.77.224/27',
  '66.249.77.32/27',
  '66.249.77.64/27',
  '66.249.77.96/27',
  '66.249.78.0/27',
  '66.249.78.32/27',
  '66.249.79.0/27',
  '66.249.79.128/27',
  '66.249.79.160/27',
  '66.249.79.192/27',
  '66.249.79.224/27',
  '66.249.79.32/27',
  '66.249.79.64/27',
  '66.249.79.96/27',
]
