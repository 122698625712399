'use client'

import clsx from 'clsx'
import { useEffect, useRef } from 'react'

interface PlaceholderProps {
  isLoading: boolean
  classes?: string
  children: React.ReactNode
}

class AnimationSyncManager {
  private static instance: AnimationSyncManager
  private referenceStartTime: number | null = null

  private constructor() {}

  static getInstance(): AnimationSyncManager {
    if (!AnimationSyncManager.instance) {
      AnimationSyncManager.instance = new AnimationSyncManager()
    }

    return AnimationSyncManager.instance
  }

  syncAnimation(animation: Animation): void {
    if (!this.referenceStartTime) {
      this.referenceStartTime = animation.startTime ? Number(animation.startTime) : null
    } else {
      animation.startTime = this.referenceStartTime
    }
  }
}

const syncManager = AnimationSyncManager.getInstance()

export const Placeholder = ({ children, classes = 'w-24 h-4', isLoading }: PlaceholderProps) => {
  const placeholderRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!placeholderRef.current) return

    const element = placeholderRef.current

    const observer = new MutationObserver(() => {
      const animation = element.getAnimations()[0]

      if (animation) {
        syncManager.syncAnimation(animation)
      }
    })

    observer.observe(element, { attributes: true })

    const animation = element.getAnimations()[0]

    if (animation) {
      syncManager.syncAnimation(animation)
    }

    return () => observer.disconnect()
  }, [isLoading])

  return isLoading ? (
    <div
      ref={placeholderRef}
      className={clsx('rounded bg-gray-700', classes)}
      style={{ animation: 'placeholder-pulse 2s ease-in-out infinite', opacity: 'var(--placeholder-opacity)' }}
    ></div>
  ) : (
    <span>{children}</span>
  )
}

export default Placeholder
